/**
 * Obfuscate long strings i.e. 4gdfs XXXX ef3i4
 * @param str
 * @param start
 * @param end
 * @param placeholder
 * @returns
 */
const obfuscateString = (str: string, start = 4, end = 5, placeholder = 'xxxx'): string => {
    const startStr = str.substring(0, start)
    const endStr = str.substring(str.length - end, str.length)

    return `${startStr} ${placeholder} ${endStr}`
}

export default obfuscateString
