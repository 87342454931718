import React from 'react'

import { resizeImage } from 'helpers/filestack'
import { getGameAssetsPolicyIds } from '@/utils/Assets'
import { Alert, Conditional, Image, Typography } from '@/components/ui'

import t from 'helpers/translation/getTranslation'
import { WalletAssetsProps } from './types';

import s from './styles.module.css'

/**
 * Displays a list of assets found in a wallet
 * @param props 
 * @returns 
 */
const WalletAssets = (props: WalletAssetsProps) => {
  const { assets = [] } = props
  // Show 9 previews, the 10th will be the remaining count
  const assetsToShowCount = 9
  // Get total game asset count, do not count tokens
  const totalAssetsQuantity = assets.reduce((acc, asset) => {
    if (getGameAssetsPolicyIds().includes(asset.policyId))
      return acc + asset.quantity

    return acc
  }, 0);
  // Only 10 assets will have metadata, to help with performance, find those assets
  const assetsWithData = assets.filter((asset) => !!asset.nftName && getGameAssetsPolicyIds().includes(asset.policyId))
  // Grab 10 assets, we could have more in the data list
  const assetsToShow = assetsWithData.slice(0, assetsToShowCount)
  // Grab the remain count
  const assetsNotShownCount = totalAssetsQuantity - assetsToShowCount

  return (
    <div>
      <Typography className={s.wallet_preview}>
        {t("pages.wallet.preview")}
      </Typography>
      <Conditional
        condition={assetsWithData.length === 0}
        component={(
          <Alert className="mb-4 text-sm" type="default">
            {t("pages.wallet.noAssets")}
          </Alert>
        )}>
        <div className={s.wallet_assets}>
          {
            assetsToShow.map((asset, index) => {
              const thumbnail = asset.thumbnail && asset.thumbnail !== "" ? resizeImage(asset.thumbnail, 150) : '/_assets/placeholder.webp'

              return (
                <Image
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  width={100}
                  height={100}
                  alt={asset.nftSerializedDisplayName}
                  title={asset.nftSerializedDisplayName}
                  src={thumbnail} />
              )
            })
          }
          {
            assetsNotShownCount > 0 &&
            <span className={s.wallet_more}>+{assetsNotShownCount}</span>
          }
        </div>
        <Typography
          className={s.assets_held}>
          {t("pages.wallet.totalAssets", totalAssetsQuantity)}
        </Typography>
      </Conditional>
    </div>
  )
}

export default WalletAssets
