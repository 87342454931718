import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { Button, Link, Modal, Pill, Tooltip } from '@/components/ui'
import { t } from 'helpers/translation'
import { useAuth } from '@/providers/AuthenticationProvider'
import { Wallet } from '@/components/ui/Icon'
import { PlayerUserType, UserType, UserVerifyCardanoHardwareWalletRequestType, UserVerifyCardanoWalletRequestType, UserVerifyEvmWalletRequestType } from '@/types/Api'
import { useVerifyCardanoHardwareWalletMutation, useVerifyCardanoWalletMutation, useVerifyEvmWalletMutation } from 'mutations'
import { HttpClientResponseError } from '@/network/httpClients'
import { ROUTE_ACCOUNT_SETTINGS_WALLET_SECTION } from 'consts/routes'
import { WALLET_CHAIN_TYPE_EVM } from 'hooks/useWallet'

import WalletVerification from '../WalletVerification'
import { WalletOnVerifyHardwareSuccessPayload, WalletOnVerifySuccessPayload } from '../WalletVerification/types'
import { WalletConnectModalProps } from './types'

import s from './styles.module.css'

const WalletVerificationModal = (props: WalletConnectModalProps) => {
  const { buttonLabel, modalTitle, buttonProps, supportedWallets, onSave, showConnectedWalletsCount, help } = props
  const { user, setUser, walletsToSync, setWalletsToSync } = useAuth()
  const [verifyingAddresses, setVerifyingAddresses] = useState<Record<string, boolean>>({})
  const { verifiedWalletAddresses = [], id } = user as UserType
  const { mutate: verifyCardanoWalletMutate } = useVerifyCardanoWalletMutation(id)
  const { mutate: verifyEvmWalletMutate } = useVerifyEvmWalletMutation(id)
  const { mutate: verifyCardanoHardwareWalletMutate } = useVerifyCardanoHardwareWalletMutation(id)

  // TODO:: We are excluding payment addresses here, this check should be removed in the future
  const connectedWalletsCount = !user ? 0 : user?.verifiedWalletAddresses.filter((filter) => filter.id !== 0).length

  /**
   * On successfully verifying server side
   */
  const handleVerificationServerSuccess = useCallback((data: PlayerUserType, walletSignedResponse: WalletOnVerifySuccessPayload | WalletOnVerifyHardwareSuccessPayload, onSuccess: () => void) => {
    const { address } = walletSignedResponse

    const { playerUser } = data

    setUser(playerUser)

    // If the saved wallet required syncing remove it from the list
    if (address && walletsToSync.includes(address)) {
      const remainingWalletsToSync = walletsToSync.filter(wallet => wallet !== address)
      setWalletsToSync(remainingWalletsToSync)
    }

    if (onSave && typeof onSave === "function")
      onSave()

    toast.success(t("pages.settings.walletVerifySuccess"))

    setVerifyingAddresses({
      ...verifyingAddresses,
      [`${address}`]: false
    })

    if (onSuccess)
      onSuccess()
  }, [onSave, setUser, setWalletsToSync, verifyingAddresses, walletsToSync])

  /**
   * On failing to verify server side
   */
  const handleVerificationServerFail = useCallback((error: HttpClientResponseError, walletSignedResponse: WalletOnVerifySuccessPayload | WalletOnVerifyHardwareSuccessPayload) => {
    toast.error(t("pages.settings.walletVerifyFail", error.message))

    setVerifyingAddresses({
      ...verifyingAddresses,
      [`${walletSignedResponse.address}`]: false
    })
  }, [verifyingAddresses])

  /**
   * On successfully verifying client side, confirm server side
   */
  const handleOnVerifySuccess = useCallback(async (walletSignedResponse: WalletOnVerifySuccessPayload, onSuccess: () => void) => {
    const { chain, cardanoPublicKey, cardanoPaymentAddresses, ...request } = walletSignedResponse

    setVerifyingAddresses({
      ...verifyingAddresses,
      [`${walletSignedResponse.address}`]: true
    })

    if (chain === WALLET_CHAIN_TYPE_EVM) {
      verifyEvmWalletMutate(request as UserVerifyEvmWalletRequestType, {
        onSuccess(data) {
          handleVerificationServerSuccess(data, walletSignedResponse, onSuccess)
        },
        onError(error) {
          handleVerificationServerFail((error as HttpClientResponseError), walletSignedResponse)
        },
      })
    }
    else {
      verifyCardanoWalletMutate({ ...request, cardanoPaymentAddresses, cardanoPublicKey } as UserVerifyCardanoWalletRequestType, {
        onSuccess(data) {
          handleVerificationServerSuccess(data, walletSignedResponse, onSuccess)
        },
        onError(error) {
          handleVerificationServerFail((error as HttpClientResponseError), walletSignedResponse)
        },
      })
    }
  }, [verifyingAddresses, handleVerificationServerSuccess, handleVerificationServerFail, verifyEvmWalletMutate, verifyCardanoWalletMutate])

  /**
   * On successfully verifying client side, confirm server side
   */
  const handleOnVerifyHarewareWalletSuccess = useCallback(async (walletSignedResponse: WalletOnVerifyHardwareSuccessPayload, onSuccess: () => void) => {
    const { chain, ...request } = walletSignedResponse

    setVerifyingAddresses({
      ...verifyingAddresses,
      [`${walletSignedResponse.address}`]: true
    })

    verifyCardanoHardwareWalletMutate(request as UserVerifyCardanoHardwareWalletRequestType, {
      onSuccess(data) {
        handleVerificationServerSuccess(data, walletSignedResponse, onSuccess)
      },
      onError(error) {
        handleVerificationServerFail((error as HttpClientResponseError), walletSignedResponse)
      },
    })
  }, [verifyCardanoHardwareWalletMutate, handleVerificationServerSuccess, handleVerificationServerFail, verifyingAddresses])

  return (
    <div className={s.wallet_verification}>
      <div className={s.wallet_verification_button}>
        <Modal
          title={modalTitle || t("wallet.selectWallet")}
          size="md"
          trigger={(setIsOpen, isOpen) => (
            <Button
              variant="primary"
              filled
              icon={<Wallet className="fill-black" />}
              {...buttonProps}
              onClick={() => setIsOpen(!isOpen)}>
              {buttonLabel || t("wallet.connect")}
            </Button>
          )}>
          {
            (setOpen) => (
              <WalletVerification
                verfiedAddresses={verifiedWalletAddresses.map(wallet => wallet.address)}
                verifyingAddresses={verifyingAddresses}
                supportedWallets={supportedWallets}
                onVerifyHardwareSuccess={(payload) => {
                  handleOnVerifyHarewareWalletSuccess(payload, () => setOpen(false))
                }}
                onVerifySuccess={(payload) => {
                  handleOnVerifySuccess(payload, () => setOpen(false))
                }} />
            )
          }
        </Modal>

        {
          help &&
          <Tooltip
            size="sm"
            variant="primary">
            {help}
          </Tooltip>
        }
      </div>
      {
        showConnectedWalletsCount &&
        <Link
          href={ROUTE_ACCOUNT_SETTINGS_WALLET_SECTION}
          className={s.verified_wallets_link}
        >
          <span className={s.verified_wallets_count}>{connectedWalletsCount}</span> {t("wallet.walletsConnected")}
        </Link>
      }
    </div>
  )
}

export default WalletVerificationModal