import React, { useState, useEffect, useCallback } from 'react'

import useWallet, { SupportedWalletType, WALLET_CHAIN_TYPE_CARDANO } from 'hooks/useWallet'
import { WalletAssetsType } from '@/types/Api'

import Wallet from './Wallet'
import { WalletVericationProps } from './types'
import ConnectWallet from '../ConnectWallet'

/**
 * Displays a list of wallets 
 * @param props 
 * @returns 
 */
const WalletVerification = (props: WalletVericationProps) => {
  const { onVerifySuccess, onVerifyHardwareSuccess, verifyingAddresses, verfiedAddresses, supportedWallets = [] } = props
  const { getAddressesWithAssets, getAddressAssets, getAddress, disconnect } = useWallet({
    debugId: 'walletVerfication'
  })

  // Currently selected wallet
  const [wallet, setWallet] = useState<SupportedWalletType>()
  // Loading UTXOs
  const [isWalletLoading, setIsWalletLoading] = useState<boolean>(false)
  // Wallet address(es)
  const [walletAddress, setWalletAddress] = useState<string | string[]>('')
  // Wallet assets
  const [walletAssets, setWalletAssets] = useState<WalletAssetsType>()
  // If Cardano, get valid paymentAddresses
  const [walletPaymentAddresses, setWalletPaymentAddresses] = useState<string[]>([])

  const initWallet = useCallback(async (selectedWallet?: SupportedWalletType) => {
    if (selectedWallet) {
      const address = await getAddress(selectedWallet)
      // This is only for Cardano
      const addresses = selectedWallet.chain === WALLET_CHAIN_TYPE_CARDANO ? await getAddressesWithAssets(selectedWallet) : []
      const assets = await getAddressAssets(selectedWallet)
      setWalletAddress(address ?? '')
      setWalletAssets(assets)
      setWalletPaymentAddresses(addresses)
      setIsWalletLoading(false)
    }
  }, [setWalletAddress, setWalletAssets, setWalletPaymentAddresses, setIsWalletLoading, getAddress, getAddressesWithAssets])

  useEffect(() => {
    // Only fire when wallet changes
    initWallet(wallet)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet])

  const onWalletReset = () => {
    if (wallet)
      disconnect(wallet)

    setWallet(undefined)
  }

  return (
    wallet ?
      <Wallet
        walletName={wallet.name}
        displayName={wallet.displayName}
        chain={wallet.chain}
        address={walletAddress}
        assets={walletAssets}
        paymentAddresses={walletPaymentAddresses}
        verfiedAddresses={verfiedAddresses}
        verifyingAddresses={verifyingAddresses}
        isLoading={isWalletLoading}
        onReset={onWalletReset}
        onVerifySuccess={onVerifySuccess}
        onVerifyHardwareSuccess={onVerifyHardwareSuccess}
      />
      :
      <ConnectWallet
        supportedWallets={supportedWallets}
        onConnectSuccess={(selectedWallet: SupportedWalletType) => {
          setWallet(selectedWallet)
          setWalletPaymentAddresses([])
          setWalletAssets([])
          setWalletAddress('')
          setIsWalletLoading(true)
        }}
      />
  )
}

export default WalletVerification