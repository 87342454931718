import useAuthMutation from 'hooks/useAuthMutation'
import {
    UserDeleteRequestType,
    UserVerifyCardanoHardwareWalletRequestType,
    UserVerifyCardanoWalletRequestType,
    UserVerifyEvmWalletRequestType,
} from '../types'
import { PlayerHttpClient } from '../network'

export const useVerifyCardanoWalletMutation = (id: number) =>
    useAuthMutation({
        mutationFn: (payload: UserVerifyCardanoWalletRequestType, token) =>
            PlayerHttpClient.setAccessToken(token).UserVerifyCardanoWallet(id, payload),
    })

export const useVerifyEvmWalletMutation = (id: number) =>
    useAuthMutation({
        mutationFn: (payload: UserVerifyEvmWalletRequestType, token) =>
            PlayerHttpClient.setAccessToken(token).UserVerifyEvmWallet(id, payload),
    })

export const useVerifyCardanoHardwareWalletMutation = (id: number) =>
    useAuthMutation({
        mutationFn: (payload: UserVerifyCardanoHardwareWalletRequestType, token) =>
            PlayerHttpClient.setAccessToken(token).UserVerifyCardanoHardwareWallet(id, payload),
    })

export const useDeleteUserMutation = (id: number) =>
    useAuthMutation({
        mutationFn: (payload: UserDeleteRequestType, token) =>
            PlayerHttpClient.setAccessToken(token).DeleteUserAccount(id, payload),
    })

export const useUndoDeleteUserMutation = (id: number) =>
    useAuthMutation({
        mutationFn: (_, token) => PlayerHttpClient.setAccessToken(token).UndoDeleteUserAccount(id),
    })
